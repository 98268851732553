<template>
    <v-dialog v-model="show" persistent max-width="400">
        <v-card>
            <v-card-title>Add Credit Card</v-card-title>
            <v-card-text>
                <v-text-field
                    type="text"
                    label="Card number (Required)"
                    autofocus
                    v-model="form.number"
                    :error="form.errors.has('number')"
                    :error-messages="form.errors.get('number')"
                    @keydown="form.errors.clear('number')"
                    required
                    ref="number"
                />
                <div class="tw-grid tw-gap-3 tw-grid-cols-2">
                    <v-text-field
                        type="text"
                        label="Expiration date (Required)"
                        v-model="form.expiry"
                        :error="form.errors.has('exp_month')"
                        :error-messages="form.errors.get('exp_month')"
                        @keydown="form.errors.clear('exp_month')"
                        required
                        ref="expiry"
                    />
                    <v-text-field
                        type="text"
                        label="CVC (Optional)"
                        v-model="form.cvc"
                        :error="form.errors.has('cvc')"
                        :error-messages="form.errors.get('cvc')"
                        @keydown="form.errors.clear('cvc')"
                        required
                        ref="cvc"
                    />
                </div>
                <v-text-field
                    type="text"
                    label="Card name (Optional)"
                    v-model="form.name"
                    :error="form.errors.has('name')"
                    :error-messages="form.errors.get('name')"
                    @keydown="form.errors.clear('name')"
                    required
                />
                <v-checkbox v-model="form.default" label="Set as default" class="mt-0"></v-checkbox>
                <v-checkbox
                    v-model="form.use_for_autopay"
                    label="Use for autopay"
                    class="mt-0"
                ></v-checkbox>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="show = false" :disabled="saving">Cancel</v-btn>
                <v-btn text color="primary" @click="save" :loading="saving">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Payment from 'payment';
import { mapGetters } from 'vuex';
import Http from '@/utils/httpClient';
import Form from '@/utils/form';

export default {
    data() {
        return {
            show: false,
            saving: false,
            form: new Form({
                number: '',
                name: '',
                expiry: '',
                cvc: '',
                default: false,
                use_for_autopay: false
            })
        };
    },
    computed: {
        ...mapGetters(['me'])
    },
    watch: {
        show(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    Payment.formatCardNumber(this.$refs.number.$el.querySelector('input'), 16);
                    Payment.formatCardExpiry(this.$refs.expiry.$el.querySelector('input'));
                    Payment.formatCardCVC(this.$refs.cvc.$el.querySelector('input'));
                });
            }
        }
    },
    methods: {
        open() {
            this.show = true;
            this.form.reset();
            this.form.errors.clear();
        },
        save() {
            this.saving = true;
            this.form.errors.clear();
            Http.post('cards', {
                name: this.form.name,
                number: this.form.number.replace(/\s/g, ''),
                exp_month: this.form.expiry.replace(/\s/g, '').split('/')[0],
                exp_year: this.form.expiry.replace(/\s/g, '').split('/')[1],
                cvc: this.form.cvc,
                default: this.form.default,
                use_for_autopay: this.form.use_for_autopay
            })
                .then(() => {
                    this.saving = false;
                    this.show = false;
                    this.$emit('success');
                    this.$root.$snackbar.open({
                        text: 'Card was successfully added.',
                        type: 'success'
                    });
                })
                .catch(({ response }) => {
                    this.saving = false;
                    this.form.errors.record(response.data);
                });
        }
    }
};
</script>
